<template>
    <div>
        <SettingsList ref="child" :title="$t('bank_accounts.plural')" :headers="headers" :items="items"
                      :loading="loading" :url="url" :add_button_title="$t('bank_accounts.create')"
                      :delete_text="$t('bank_accounts.delete')"
                      :delete_confirmation_text="$t('bank_accounts.delete_confirmation')"
                      @show-dialog="showDialog" @view-dialog="showDialog" @update-dialog="showDialog"
                      @get-data="getData">
        </SettingsList>

        <!--Tag Dialog-->
        <Dialog :show="show_item_dialog" max_width="500px" :header="item_dialog_header"
                :is_loading="item_dialog_loading" :action="item_action" @close-dialog="closeItemDialog"
                @submit-dialog="submitItemDialog">
            <template slot="content">
                <v-form autocomplete="off" class="white_bg_form">
                    <v-row dense>
                        <v-col cols="12">
                            <v-text-field name="beneficiary_name" type="text" class="custom_field" v-model="item.beneficiary_name"
                                          :label="$t('general.beneficiary_name')" outlined clearable :disabled="isDisabled"
                                          @input="$v.item.beneficiary_name.$touch()" @blur="$v.item.beneficiary_name.$touch()"
                                          :error-messages="fieldErrors('beneficiary_name')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field name="name" type="text" class="custom_field" v-model="item.name"
                                          :label="$t('general.name')" outlined clearable :disabled="isDisabled"
                                          @input="$v.item.name.$touch()" @blur="$v.item.name.$touch()"
                                          :error-messages="fieldErrors('name')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field name="swift" type="text" class="custom_field" v-model="item.swift"
                                          :label="$t('general.swift')" outlined clearable :disabled="isDisabled"
                                          @input="$v.item.swift.$touch()" @blur="$v.item.swift.$touch()"
                                          :error-messages="fieldErrors('swift')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field name="address" type="text" class="custom_field" v-model="item.address"
                                          :label="$t('general.address')" outlined clearable :disabled="isDisabled"
                                          @input="$v.item.address.$touch()" @blur="$v.item.address.$touch()"
                                          :error-messages="fieldErrors('address')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field name="phone_number" type="text" class="custom_field"
                                          v-model="item.phone_number" :label="$t('general.phone_number')" outlined
                                          clearable :disabled="isDisabled" @input="$v.item.phone_number.$touch()"
                                          @blur="$v.item.phone_number.$touch()"
                                          :error-messages="fieldErrors('phone_number')">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field name="iban" type="text" class="custom_field" v-model="item.iban"
                                          :label="$t('general.iban')" outlined clearable :disabled="isDisabled"
                                          @input="$v.item.iban.$touch()" @blur="$v.item.iban.$touch()"
                                          :error-messages="fieldErrors('iban')">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </Dialog>
    </div>
</template>

<script>
    import SettingsList from '../../components/SettingsList.vue'
    import Dialog from '../../components/Dialog.vue'
    import {validationMixin} from 'vuelidate'
    import {required} from 'vuelidate/lib/validators'

    export default {
        components: {
            SettingsList, Dialog
        },
        mixins: [validationMixin],
        validations: {
            item: {
                beneficiary_name: {
                    required
                },
                name: {
                    required
                },
                swift: {
                    required
                },
                address: {
                    required
                },
                phone_number: {
                    required
                },
                iban: {
                    required
                }
            }
        },
        data: self => ({
            // datatable
            headers: [],
            items: [],
            loading: false,
            url: 'bank-accounts',

            // dialog
            show_item_dialog: false,
            item_dialog_header: null,
            item_dialog_loading: false,
            item: {
                beneficiary_name: null,
                name: null,
                swift: null,
                address: null,
                phone_number: null,
                iban: null
            },
            item_id: null,
            item_action: null
        }),
        computed: {
            isDisabled() {
                return this.item_action === 'view';
            },
            locale() {
                return this.$i18n.locale;
            }
        },
        created() {
            this.headers = this.getHeaders();
        },
        mounted() {
            this.getData();
        },
        watch: {
            locale(val) {
                this.headers = this.getHeaders();
            }
        },
        methods: {
            // return headers array
            getHeaders() {
                return [
                    {text: `${this.$t('general.beneficiary_name')}`, value: 'beneficiary_name', sortable: true},
                    {text: `${this.$t('general.name')}`, value: 'name', sortable: true},
                    {text: `${this.$t('general.swift')}`, value: 'swift', sortable: true},
                    {text: `${this.$t('general.address')}`, value: 'address', sortable: true},
                    {text: `${this.$t('general.phone_number')}`, value: 'phone_number', sortable: true},
                    {text: `${this.$t('general.iban')}`, value: 'iban', sortable: true},
                    {text: `${this.$t('datatable.actions')}`, value: 'actions', align: 'right', sortable: false}
                ];
            },

            // form field errors
            fieldErrors(prop) {
                const errors = [];
                if (!this.$v.item[prop].$dirty) return errors;
                !this.$v.item[prop].required && errors.push(this.$t('validation.required'));
                return errors;
            },
            // get data from API
            async getData() {
                this.loading = true;
                let response = await this.$store.dispatch('apiRequest', {method: 'get', url: this.url});

                // successful response
                if (!response.response) {
                    this.items = response.data;
                }

                this.loading = false;
            },
            // show item dialog
            showDialog(item, action) {
                this.show_item_dialog = true;
                this.item_action = action;
                this.item_dialog_header = action === 'create' ? this.$t('bank_accounts.create') :
                    action === 'view' ? this.$t('bank_accounts.view') : this.$t('bank_accounts.update');

                if (item) {
                    this.setItemInfo(item);
                }
            },
            // set item info for update and view
            setItemInfo(item) {
                this.item_id = item.id;

                for (let prop in this.item) {
                    this.item[prop] = item[prop];
                }
            },
            // close item dialog
            closeItemDialog() {
                this.$v.item.$reset();
                this.show_item_dialog = false;
                this.item_id = null;
                this.item_action = null;

                for (let prop in this.item) {
                    this.item[prop] = null;
                }
            },
            // submit item dialog
            async submitItemDialog() {
                this.$v.item.$touch();

                // login data are valid
                if (!this.$v.item.$invalid) {
                    this.item_dialog_loading = true;
                    let item = Object.assign({}, this.item);

                    let response = await this.$refs.child.submitItem(item, this.item_id);

                    // stop dialog submit button loading
                    this.item_dialog_loading = false;

                    // successful response
                    if (!response.response) {

                        // close item dialog
                        this.closeItemDialog();

                        // get items
                        this.getData();
                    }
                }
            }
        }
    }
</script>